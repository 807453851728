














import { Component, Vue, PropSync } from "vue-property-decorator";
@Component({
  components: {
    Objetivos: () =>
      import("@/views/pacientes/objetivos/paciente-objetivos.vue")
  }
})
export default class CompDialogObjetivos extends Vue {
  @PropSync("dialog", { type: Boolean }) syncedDialog!: Boolean;
}
